import BaseCom from "../../../BaseCom";
import Icon from "../../../Icon";
import L from "../../../Lang";
import { LayoutGlobal } from "../../../Layout";
import { withParamsAndNavigate } from "../../../WithParamsAndNavigate";
import AppActions from "../../AppActions";
import AppOptions from "../../AppOptions";
import DataView from "../../DataView";
import View from "../../Framework/View";
import OptionButton from "../../OptionButton";

class ControllerList extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            list: []
        };
    }

    async componentDidMount() {
        await this.getLoginOrExitAsync();
        LayoutGlobal.setConsoleMode(true);
    }

    delete = async (row) => {
        
    }

    render() {
        return (
            <View title="Controller List">
                <AppOptions>
                    <OptionButton primary link="new"><L>Create Controller</L></OptionButton>
                </AppOptions>
                <AppActions showBack />
                <DataView
                    api={'/api/controller/list?projectID=' + this.props.params.projectID}
                    actions={[
                        { label: <Icon icon="faPencil" />, onClick: (row) => this.props.navigate(String(row.id)) },
                        
                    ]}
                    onRowClick={(row) => this.props.navigate(String(row.id))}
                    />
            </View>
        )
    }
}

export default withParamsAndNavigate(ControllerList);