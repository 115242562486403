import BaseCom from "../../BaseCom";
import ToolHeading from "../ToolHeading";

export default class View extends BaseCom {
    render() {
        return <section>
            <ToolHeading>{this.props.title}</ToolHeading>
            <div className="container-fluid">
                {this.props.children}
            </div>
        </section>
    }
}