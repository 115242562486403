import BaseCom from "../../../BaseCom";
import FormInputV2 from "../../../FormInputV2";
import Icon from "../../../Icon";
import L from "../../../Lang";
import { LayoutGlobal } from "../../../Layout";
import { withParamsAndNavigate } from "../../../WithParamsAndNavigate";
import AppActions from "../../AppActions";
import View from "../../Framework/View";
import OptionButton from "../../OptionButton";
import SaveButton from "../../SaveButton";
import UForm from "../../UForm";

class ControllerEdit extends BaseCom {
    
        constructor(props) {
            super(props);
            this.state = {
                info: {
                    projectID: this.props.params.projectID
                }
            };
        }
    
        async componentDidMount() {
            await this.getLoginOrExitAsync();
            LayoutGlobal.setConsoleMode(true);
            await this.fillListAsync('/api/entity/list?projectID=' + this.props.params.projectID, 'entityList');
            await this.fillListAsync('/api/controllermethodtype/list', 'controllerMethodTypeList');
        }

        addMethod = () => {
            let methods = this.state.info.methods ? [...this.state.info.methods] : [];
            methods.push({});
            this.setState({ info: { ...this.state.info, methods: methods } });
        }

        deleteMethod = (idx) => {
            let methods = this.state.info.methods ? [...this.state.info.methods] : [];
            methods.splice(idx, 1);
            this.setState({ info: { ...this.state.info, methods: methods } });
        }

        render() {
            return (
                <View title="Edit Controller">
                    <UForm api="/api/controller"
                        model={this.state.info}
                        id={this.props.params.id}
                        onLoad={(info) => this.setState({ info: info })}
                        goBack={() => this.props.navigate(-1)}>
    
                        <FormInputV2 model={this.state.info} name="name" label="Controller Name" required />
                        <FormInputV2 model={this.state.info} name="ident" label="Identity" required />
                        <FormInputV2 model={this.state.info} name="description" label="Description" />

                        <FormInputV2 model={this.state.info} name="entityID" label="Entity" type="select" options={this.state.entityList} required />

                        {this.state.info?.methods?.map((i, idx) => <div key={idx} className="d-flex align-items-center gap-2">
                            <FormInputV2 model={i} name="name" placeholder="Method Name" required />
                            <FormInputV2 model={i} name="path" placeholder="Path" required />
                            <FormInputV2 model={i} name="typeID" placeholder="Type" type="select" options={this.state.controllerMethodTypeList} required />
                            <button type="button" className="btn btn-danger" onClick={() => this.deleteMethod(idx)}><Icon icon="faTrash" /></button>
                        </div>)}

                        <OptionButton onClick={this.addMethod}><L>Add Method</L></OptionButton>

    
                        <AppActions showBack>
                            <SaveButton />
                        </AppActions>
                    </UForm>
                </View>
            );
        }

}

export default withParamsAndNavigate(ControllerEdit);