import BaseCom from "../../BaseCom";
import { LayoutGlobal } from "../../Layout";
import { withParamsAndNavigate } from "../../WithParamsAndNavigate";
import AppActions from "../AppActions";
import View from "../Framework/View";
import OptionButton from "../OptionButton";

class ProjectView extends BaseCom {
    async componentDidMount() {
        await this.getLoginOrExitAsync();
        LayoutGlobal.setConsoleMode(true);
        await this.fillItemAsync('/api/project/item/' + this.props.params.id, 'item');
    }

    render() {
        return (
            <View title={this.state.item?.name}>
                <div className="row text-center">
                    <div className="col-md">
                        <OptionButton link="entity">Entities</OptionButton>
                    </div>
                    <div className="col-md">
                        <OptionButton link="module">Modules</OptionButton>
                    </div>
                    <div className="col-md">
                        <OptionButton link="controller">Controller</OptionButton>
                    </div>
                </div>
                <AppActions showBack />
            </View>
        )
    }
}

export default withParamsAndNavigate(ProjectView);