import { getLang } from "../shared/GLang";
import BaseCom from "./BaseCom";

export default class FormInputV2 extends BaseCom {

    change = (e) => {
        this.props.model[this.props.name] = e.target.value;
        this.forceUpdate();
    }

    toggle = (e) => {
        this.props.model[this.props.name] = e.target.checked;
        this.forceUpdate();
    }

    render() {
        if (this.props.type === 'switch') {
            let boolValue = this.props.model?.[this.props.name];
            return (
                <div className="form-group">
                    <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" id={this.props.id} checked={boolValue} onChange={this.toggle} disabled={this.props.disabled} />
                    <label className="form-check-label" htmlFor={this.props.id}>{this.props.label}</label>
                </div>
                </div>
            );
        }
        return (
            <div className="form-group">
                {this.props.label && <label className="form-label">{this.props.required ? '*' : ''} {getLang(this.props.label)}</label>}
                <div className="input-group">
                    {
                    
                    
                    this.props.type === 'select' ? (
                        <select className="form-select" value={this.props.model?.[this.props.name] || ''} onChange={this.change}>
                            <option value="">{getLang('Select')}</option>
                            {this.props.options?.map((i, idx) => <option key={idx} value={i.id}>{getLang(i.name)}</option>)}
                        </select>

                    ) :
                    
                    
                    this.props.type === 'textarea' ? (
                        <textarea className="form-control" value={this.props.model?.[this.props.name] || ''} onChange={this.change} placeholder={this.props.placeholder} />
                    )
                        :
                        (
                            <input type={this.props.type || 'text'} className="form-control" value={this.props.model?.[this.props.name] || ''} onChange={this.change} placeholder={this.props.placeholder} />
                        )}
                </div>
            </div>
        )
    }
}